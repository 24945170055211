import { createObservableDataAction, IAction } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetProductColorWashCareByItemInput } from '../DataActionExtension.g';
import { IK3ProductColorWashCareTable } from '../DataServiceEntities.g';

export default createObservableDataAction({
    action: <IAction<IK3ProductColorWashCareTable[]>>retailAction,
    input: inputData => {
        const itemId = inputData.data.product.result.ItemId;
        const dataAreaId = inputData.requestContext.channel.InventLocationDataAreaId;
        return createGetProductColorWashCareByItemInput({ Paging: { Top: 250 } }, itemId, dataAreaId);
    }
});
